import React from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "assets/images/logo.svg";
import barsLight from "assets/images/bars-light.svg";
import signIn from "assets/images/signin.svg";

export const Header = props => {
  const { active, urlCampaign } = props;
  const param = urlCampaign ? urlCampaign : "";

  return (
    <header>
      <div className="container">
        <Navbar variant="dark" expand="lg" className="row">
          <div className="col-lg-4 logo-container">
            {/* logo */}
            <Link className="logo" to={{ pathname: `/${param}` }}>
              <img src={logo} alt="Driver Recruitment" width="89" />
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src={barsLight} alt="Driver Recruitment" width="20" />
            </Navbar.Toggle>
          </div>
          <div className="col-lg-8">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav activeKey={active}>
                {/* Menu */}
                <Nav.Link href="" eventKey="home" className="cursor-none">
                  <span>Choose DPD</span>
                </Nav.Link>
                {/* <Nav.Link href={`/choice${param}`} eventKey="choice">
                  <span>Choice Driver</span>
                </Nav.Link> */}
                <Nav.Link
                  href={`${process.env.REACT_APP_PORTAL_HOST}login${param}`}
                  target="_blank"
                >
                  <span>
                    Sign In <img src={signIn} alt="Sign In" width="15px" />
                  </span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
