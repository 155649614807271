export const MOBILE_BREAKPOINT = 992;

export const ANIMATION_DELAY = {
  default: window.innerWidth < MOBILE_BREAKPOINT ? 50 : 300,
  fast: 100,
  slow: 400,
};

export const HOME_CARDS_CONTENT = [
  {
    subheader: "Owner Driver",
    header: "From £50K to £60K per route",
    list: [
      "You are responsible for your own tax and insurance contributions",
      "Self-employed",
      "Lease and Own Van options available",
    ],
    button: "I’m Interested, tell me more",
    anchor: "driver",
    animate: "left",
  },
  {
    subheader: "Owner Driver Worker",
    header: "Earn from £27K",
    list: [
      "Sick pay",
      "Paid Holiday",
      "Pension",
      "Self-employed",
      "Lease and Own Van options available",
    ],
    button: "I’m Interested, tell me more",
    anchor: "worker",
    // animate: "left",
  },
  {
    subheader: "Employed Driver",
    header: "Earn from £24K",
    list: [
      "DPD employee",
      "Vehicle Provided",
      "Sick pay",
      "Holiday pay",
      "Pension",
      "Part of a diverse and inclusive team",
    ],
    button: "I’m Interested, tell me more",
    anchor: "https://www.dpd.co.uk/careers/current_jobs.jsp",
    externalLink: true,
    animate: "right",
    newTab: true,
  },
  // {
  //   subheader: "CHOICE DRIVER",
  //   header: "Competitive stop rates",
  //   list: [
  //     "Choose the days you want to work",
  //     "Freedom and choice to fit your lifestyle",
  //     "Use your own vehicle",
  //     "Easy to use app to manage your earnings",
  //     "You are responsible for your own tax and insurance contributions"
  //   ],
  //   button: "I’m Interested, tell me more",
  //   anchor: "/choice",
  //   externalLink: true,
  //   animate: "right",
  // },
];

export const APPLY_ACCORDION = [
  {
    item: 0,
    header: "What would I be driving?",
    content:
      "We have a wide range of lease vehicles to choose from, including Elites and E3.5 tonne, or you can use your own van.",
  },
  {
    item: 1,
    header: "What are the insurances I need if I want to use my own van?",
    content: (
      <>
        Goods in Transit (GIT) £40,000
        <br />
        Public Liability £1M
        <br />
        Employer Liability £5M
        <br />
        Motor Insurance
      </>
    ),
  },
  {
    item: 2,
    header:
      "If we have a lease vehicle from DPD can we use it when we finish work?",
    content:
      "Yes - you are paying for the vehicle and can use it as you would your own vehicle.",
  },
  {
    item: 3,
    header: "What does self employed mean?",
    content:
      "A person is self-employed if they run their business for themselves and take responsibility for its success or failure. Self-employed workers are not paid through PAYE, and they do not have the rights and responsibilities of an employee. A worker must tell HM Revenue and Customs (HMRC) if they think they have become self-employed.",
  },
  {
    item: 4,
    header: "What happens once I apply?",
    content:
      "You will be contacted by one of our recruitment team and given some more information about how it works, if you are interested we will arrange a google hangout to answer any questions you may have, then you willl meet with your local depot in person to see what options are available to you.",
  },
  {
    item: 5,
    header: "Do I need experience?",
    content:
      "If you have experience of driving on UK roads, and have a UK licence, this could be opportunity for you.",
  },
  {
    item: 6,
    header: "How do I get paid?",
    content: "You get paid per stop, either a collection or a delivery.",
  },
  {
    item: 7,
    header: "How do I get in touch?",
    content: (
      <>
        <a href="mailto:work@dpd.co.uk" target="_blank" rel="noreferrer">
          work@dpd.co.uk
        </a>
      </>
    ),
  },
];

export const APPLY_CONTENT = [
  {
    title: "Be 21 years or older",
    content:
      "To apply to become a DPD driver you'll need to meet the age requirements of 21 years or older.",
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT
        ? ANIMATION_DELAY.fast
        : ANIMATION_DELAY.slow,
  },
  {
    title: "UK Passport / Proof of Right to Work",
    content: (
      <>
        A valid UK passport or Proof of right to work in the UK is required,
        further information can be found at{" "}
        <a
          href="https://www.gov.uk/prove-right-to-work"
          target="_blank"
          rel="noreferrer"
        >
          gov.uk/prove-right-to-work
        </a>
      </>
    ),
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT ? ANIMATION_DELAY.fast : 500,
  },
  {
    title: "Have a valid UK driver’s licence",
    content:
      "To apply to be a driver with DPD you must have a valid, full, UK driver's licence. You will also be asked to upload an image of the front and back of your driver's licence during the application process. When uploading the images, make sure the photos are clear and in focus to prevent them from being rejected.",
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT ? ANIMATION_DELAY.fast : 600,
  },
  {
    title: "National insurance number",
    content:
      "You will be asked to upload an image of the front of your national insurance card during the application process. If you don't have your national insurance card, don't worry as you can also send us a photo of your latest P45 or P60. When uploading the images, make sure the photos are clear and in focus to prevent them from being rejected.",
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT ? ANIMATION_DELAY.fast : 700,
  },
  {
    title: "Disclosure Receipt",
    content: (
      <>
        As part of our application process you'll be asked to upload a copy of
        your basic DBS check within the first 4 weeks of your start date. To
        obtain a copy of your DBS check you can apply online at{" "}
        <a
          href="https://www.gov.uk/request-copy-criminal-record"
          target="_blank"
          rel="noreferrer"
        >
          gov.uk/request-copy-criminal-record
        </a>
      </>
    ),
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT ? ANIMATION_DELAY.fast : 800,
  },
  {
    title: "Pass a background check",
    content:
      "You will need to be entitled to work in the UK and pass a background and criminal record check which includes, but is not limited to, a review of your driving licence records.",
    animationDelay:
      window.innerWidth < MOBILE_BREAKPOINT ? ANIMATION_DELAY.fast : 900,
  },
];

export const APPLY_TOPCONTENT = [
  {
    title: "Paid Training",
    content: "Receive classroom and on the road training whilst still earning",
  },
  {
    title: "Free uniform and livery",
    content:
      "We want you to look good out on the road so if you use your own vehicle we’ll pay for the livery and installation",
  },
  {
    title: "Fuel support",
    content:
      "To help support your business with the rising costs of fuel we offer a number of Fuel Support initiatives including: Fuel cards; On site electric charging; Home charger install contribution of up to £350; Fuel supplement payments.",
  },
  {
    title: "Dedicated support",
    content:
      "Your backup team will include dedicated admin support from our Owner Driver team, and a relationship manager to help with any operational queries",
  },
  {
    title: "Reward great performance",
    content:
      "Opportunities throughout the year to earn more by delivering great service, and supporting us at peak.",
  },
];

export const REGISTER_STEPS = {
  EMPTY: 0,
  MESSAGE: 1,
  JOURNEY: 2,
  APPLY: 3,
  DETAILS: 4,
  SUMMARY: 5,
  ACKNOWLEDGEMENT: 6,
};

export const SESSION_STORAGE_KEYS = {
  PROSPECT_ID: "prospectId",
  EMAIL: "email",
  AGREEMENT_CHECKED: "agreeChecked",
  DETAILS: "details",
  SALUTATIONS: "salutations",
  REFERENCE_NUMBER: "referenceNumber",
  MESSAGE_PAGE_COMPLETE: "messagePageComplete",
  JOURNEY_PAGE_COMPLETE: "journeyPageComplete",
};

export const TAB_STATUS = {
  DISABLED: 1,
  ACTIVE: 2,
  COMPLETED: 3,
};

export const API_STEP_KEYS = {
  [REGISTER_STEPS.MESSAGE]: "page1Completed",
  [REGISTER_STEPS.JOURNEY]: "page2Completed",
  [REGISTER_STEPS.APPLY]: "page3Completed",
  [REGISTER_STEPS.SUMMARY]: "page4Completed",
};

export const API_ERROR_CODES = {
  EMAIL_NOT_FOUND: "1004",
  EMAIL_EXPIRED: "1030",
};

export const GENERAL_ERROR_MSG = "We are currently unable to process this request, please try again later";


export const DEPOT_LIST = ["South Down","Bolton","Lincoln","Portsmouth","London South","Dundee","Warrington","Northern Highlands","Glasgow","Bridgwater","Isle Of Man","Aberdeen","Docklands Hub","Enfield","Deeside","Cumbernauld","West N.ireland","Durham","Gloucester","Newbury","Dublin","Eastbourne","Southampton","Jersey","North London","Sittingbourne","Swansea","Basildon","Leeds","Bristol","Park Royal","Heathrow","Stoke","Preston","Cardiff","Croydon","Manchester","Snetterton","Dartford","Carlisle","Athlone","Glasgow Eurocentral","Maidstone","South West","Bradford","Liverpool","Arran","Middleton","Isle Of Wight","Goole","Thames","Highlands & Islands","Dudley Port","Westerham","Sheffield","Manchester Hub.","Guernsey","Hinckley","Birmingham","Radlett","Nottingham","London City","Cornwall","Abergele","Southall","Wolverhampton","Crawley","Greenford","Dagenham","West London","Raunds","Fermanagh","Swale","Ipswich","Slough","Milton Keynes","Norwich","Newtown","Tyrone","Springfield","Burgess Hill","Newcastle","Leicester","Down","Bicester","Basingstoke","Worldnet","Chelmsford","Argyll","Teesside","Brighton","High Wycombe","Tannochside","Barking","Dunstable","Exeter","Edinburgh","Worcester","Peterborough","Orkney Shetland","Guildford","Feltham","Bournemouth","Tyneside","Swindon","Gatwick","Inverness","York","North Antrim","Antrim","London Docklands","Carnforth","Reading","London"]
